// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NETWORK } from '@arianee/arianeejs';

import { defaultProdEnvironment } from './default/environment.default.prod';
import { featureFlipCommonDefautPROD } from './featureFlips/featureFlip.default.prod';

export const environment = {
	...defaultProdEnvironment,
	appType: 'web',
	name: 'arianee',
	isBaidu: false,
	batchAPI: { androidAPIKey: '', iOSAPIKey: '' },
	backupSecret: '',
	passphraseSecret: '',
	features: {
		waitingIdentity: false,
		arianeeEvent: true,
	},
	slack: {
		apikey: '',
	},
	webIdGoogleDrive: '',
	featureFlips: featureFlipCommonDefautPROD([
		{
			name: 'callFaucetOnLaunch',
			activated: false,
			description: 'request poa faucet on call',
		},
		{
			name: 'blockChainWatcherEventOff',
			activated: true,
			description: 'turn OFF blochain event',
		},
		{
			name: 'landingClaimWithAddress',
			activated: true,
			description: 'Show claim with address form on the landing page',
		},
	]),
	network: NETWORK.polygon,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
